import { useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import '../App.css';
import ApiMethods from '../ApiHelper/ApiMethods';
import apiMethodsInstance from '../ApiHelper/ApiMethods';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';

function SurveyLanding() {
    const location = useLocation();
    const { appsGuid: paramsAppsGuid } = useParams();
    const { requestId: paramsRequestId } = useParams();
    const { requestId: requestIdFromLocation } = location.state || { appsGuid: paramsAppsGuid, requestId: paramsRequestId };
    const navigate = useNavigate();

    const [appsGuid, setAppsGuid] = useState(paramsAppsGuid);
    const [requestId, setRequestId] = useState(paramsRequestId);
    const [rating, setRating] = useState(0);
    const [surveyDescription, setSurveyDescription] = useState('');
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [surveyData, setsurveyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedStatus, setselectedStatus] = useState('');

    const fetchSurveyData = async () => {
        setLoading(true);
        try {
            await ApiMethods.login();

            let surveyResponse;
            try {
                surveyResponse = await ApiMethods.getSurvey(appsGuid, requestId);
            } catch (error) {
                console.error(error);
            }

            const surveyParsedData = JSON.parse(surveyResponse.data);
            setsurveyData(surveyParsedData);
            const fetchedAppsGuid = surveyParsedData.AppsGuid;
            const fetchedRequestId = surveyParsedData.RequestId;
            setAppsGuid(fetchedAppsGuid);
            setRequestId(fetchedRequestId);

            if (!surveyParsedData.SurveyEligible && (surveyParsedData.PreviousResponseId === 0 || surveyParsedData.PreviousResponseId === null)) {
                setError("We're sorry! An error has occurred. Please reload this page and try again.");
                setLoading(false);
                return;
            }

            if (!surveyParsedData.SurveyEligible && (surveyParsedData.PreviousResponseId !== 0 && surveyParsedData.PreviousResponseId !== null)) {
                navigate('/thankyou', {
                    state: {
                        logo: surveyParsedData.Agency.ReviewsLogo,
                        agency: surveyParsedData.Agency.HomePageLink
                    }
                });
                return;
            }

            if (!surveyParsedData.ReviewsEligible) {
                switch (surveyParsedData.Agency.RecordType) {
                    case "GTC":
                        navigate('/error', {
                            state: {
                                header: "We're sorry!",
                                message: "The Global Travel Collection travel agent you are looking for does not yet have a published profile.",
                                linkPrefix: "Please visit",
                                linkText: "globaltravelcollection.com/contact-us",
                                linkHref: "https://www.globaltravelcollection.com/contact",
                                linkSuffix: "to be connected with your agent. Thank you.",
                            },
                        });
                        return;

                    case "Barrhead":
                        navigate('/error', {
                            state: {
                                header: "We're sorry!",
                                message: "The Barrhead travel agent you are looking for does not yet have a published profile.",
                                linkPrefix: "Please visit",
                                linkText: "barrheadtravel.co.uk",
                                linkHref: "https://www.barrheadtravel.co.uk/",
                                linkSuffix: "to be connected with your agent. Thank you.",
                            },
                        });
                        return;

                    case "Altour":
                        navigate('/error', {
                            state: {
                                header: "We're sorry!",
                                message: "The Altour travel agent you are looking for does not yet have a published profile.",
                                linkPrefix: "Please visit",
                                linkText: "altour.com",
                                linkHref: "https://www.altour.com",
                                linkSuffix: "to be connected with your agent. Thank you.",
                            },
                        });
                        return;

                    default:
                        break;
                }
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            navigate('/wrongUrl');
        }
    };

    useEffect(() => {
        if (!surveyData) {
            fetchSurveyData();
        }
    }, [appsGuid, requestId]);

    const [yesReasons, setYesReasons] = useState({
        yesReason1: false,
        yesReason2: false,
        yesReason3: false,
        yesReason4: false,
        yesReason5: false,
        yesReason6: false,
    });
    const [yesOtherReason, setYesOtherReason] = useState('');

    const [noReasons, setNoReasons] = useState({
        noReason1: false,
        noReason2: false,
        noReason3: false,
        noReason4: false,
        noReason5: false,
        noReason6: false,
    });
    const [noOtherReason, setNoOtherReason] = useState('');

    const changeRating = (newRating) => {
        setRating(newRating);
        if (errors.rating) {
            setErrors(prevErrors => ({ ...prevErrors, rating: false }));
        }
    };

    const handleDescriptionChange = (event) => {
        const value = event.target.value;
        setSurveyDescription(value);

        if (value.length > 2000) {
            setErrors(prevErrors => ({ ...prevErrors, surveyDescriptionLength: true }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, surveyDescriptionLength: false }));
        }

        if (value === '') {
            setErrors(prevErrors => ({ ...prevErrors, surveyDescription: true }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, surveyDescription: false }));
        }
    };

    const handleRadioChange = (e) => {
        const newStatus = e.target.value;
        setselectedStatus(newStatus);

        if (newStatus === 'Y' || newStatus === 'P') {
            setYesReasons({
                yesReason1: false,
                yesReason2: false,
                yesReason3: false,
                yesReason4: false,
                yesReason5: false,
                yesReason6: false,
            });
            setYesOtherReason('');
            setErrors((prevErrors) => ({ ...prevErrors, yesReasons: false, yesOtherReason: false }));
        } else if (newStatus === 'N') {
            setNoReasons({
                noReason1: false,
                noReason2: false,
                noReason3: false,
                noReason4: false,
                noReason5: false,
                noReason6: false,
            });
            setNoOtherReason('');
            setErrors((prevErrors) => ({ ...prevErrors, noReasons: false, noOtherReason: false }));
        }

        if (errors.selectedStatus) {
            setErrors((prevErrors) => ({ ...prevErrors, selectedStatus: false }));
        }
    };

    const handleYesOtherReasonChange = (o) => {
        const value = o.target.value;
        setYesOtherReason(value);

        if (value === '') {
            setErrors(prevErrors => ({ ...prevErrors, yesOtherReason: true }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, yesOtherReason: false }));
        }
    }

    const handleNoOtherReasonChange = (o) => {
        const value = o.target.value;
        setNoOtherReason(value);

        if (value === '') {
            setErrors(prevErrors => ({ ...prevErrors, noOtherReason: true }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, noOtherReason: false }));
        }
    }

    const handleYesReasonsChange = (reason) => {
        const updatedYesReasons = { ...yesReasons, [reason]: !yesReasons[reason] };

        setYesReasons(updatedYesReasons);

        if (reason === 'yesReason6' && yesReasons[reason]) {
            setYesOtherReason('');
        }

        const hasSelectedReasons = Object.values(updatedYesReasons).some(val => val === true);

        setErrors(prevErrors => ({ ...prevErrors, yesReasons: !hasSelectedReasons }));

        if (updatedYesReasons.yesReason6 && yesOtherReason.trim() === '') {
            setErrors(prevErrors => ({ ...prevErrors, yesOtherReason: true }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, yesOtherReason: false }));
        }
    };

    const handleNoReasonsChange = (reason) => {
        const updatedNoReasons = { ...noReasons, [reason]: !noReasons[reason] };

        setNoReasons(updatedNoReasons);

        if (reason === 'noReason6' && noReasons[reason]) {
            setNoOtherReason('');
        }

        const hasSelectedReasons = Object.values(updatedNoReasons).some(val => val === true);

        setErrors(prevErrors => ({ ...prevErrors, noReasons: !hasSelectedReasons }));

        if (updatedNoReasons.noReason6 && noOtherReason.trim() === '') {
            setErrors(prevErrors => ({ ...prevErrors, noOtherReason: true }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, noOtherReason: false }));
        }
    };

    const [starDimension, setStarDimension] = useState('50px');

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
        setErrors({});
        setError('');

        let errors = {};

        if (rating === 0) errors.rating = true;
        if (surveyDescription.trim() === '') errors.surveyDescription = true;
        if (surveyDescription.length > 2000) errors.surveyDescriptionLength = true;
        if (!selectedStatus) errors.selectedStatus = true;

        const selectedYesReasons = Object.values(yesReasons).some(reason => reason) || yesOtherReason.trim() !== '';
        const selectedNoReasons = Object.values(noReasons).some(reason => reason) || noOtherReason.trim() !== '';

        if (selectedStatus === 'Y' || selectedStatus === 'P') {
            if (!selectedYesReasons) errors.yesReasons = 'Make sure you selected one or more options';
            if (yesReasons.yesReason6 && yesOtherReason.trim() === '') errors.yesOtherReason = 'Please provide a reason';
        }
        if (selectedStatus === 'N') {
            if (!selectedNoReasons) errors.noReasons = 'Make sure you selected one or more options';
            if (noReasons.noReason6 && noOtherReason.trim() === '') errors.noOtherReason = 'Please provide a reason';
        }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            setError('One or more fields requires attention. Please, check and try again.');
            return;
        }

        const submittedInfo = {
            AppsGuid: appsGuid,
            RequestId: requestId,
            OverallRating: rating,
            ClientComment: surveyDescription,
            Booked: selectedStatus,
            BookedReason: [
                ...(yesReasons.yesReason1 ? [{ AnswerText: "He or She was knowledgeable about my travel destination", AddlComent:"" }] : []),
                ...(yesReasons.yesReason2 ? [{ AnswerText: "Working with this agent was convenient and easy", AddlComent: "" }] : []),
                ...(yesReasons.yesReason3 ? [{ AnswerText: "The agent provided added value in the planning process", AddlComent: "" }] : []),
                ...(yesReasons.yesReason4 ? [{ AnswerText: "The agent crafted the perfect trip for me", AddlComent: "" }] : []),
                ...(yesReasons.yesReason5 ? [{ AnswerText: "I was referred to this agent from a friend or family member", AddlComent: "" }] : []),
                ...(yesReasons.yesReason6 ? [{ AnswerText: "Other", AddlComent: yesOtherReason }] : []),

                ...(noReasons.noReason1 ? [{ AnswerText: "Poor communication - I never heard back from this agent", AddlComent: "" }] : []),
                ...(noReasons.noReason2 ? [{ AnswerText: "The agent didn�t offer enough value or knowledge about my trip", AddlComent: "" }] : []),
                ...(noReasons.noReason3 ? [{ AnswerText: "Working with this agent was expensive", AddlComent: "" }] : []),
                ...(noReasons.noReason4 ? [{ AnswerText: "I ended up not taking the trip", AddlComent: "" }] : []),
                ...(noReasons.noReason5 ? [{ AnswerText: "I planned the trip by myself", AddlComent: "" }] : []),
                ...(noReasons.noReason6 ? [{ AnswerText: "Other", AddlComent: noOtherReason }] : []),
            ]
        };

        try {
            const response = await ApiMethods.submitSurvey(submittedInfo);
            navigate('/thankyou', { state: { logo: surveyData.Agency.ReviewsLogo, agency: surveyData.Agency.HomePageLink }});
        } catch (error) {
            setError('Error submitting review. Please try again later.');
        }
    };    

    //Stars dimensions
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setStarDimension('40px');
            } else {
                setStarDimension('60px');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function formatDate(isoDateString) {
        const date = new Date(isoDateString);

        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }

    function getLinkText(url) {
        if (url.includes('travelleaders')) {
            return 'Travelleaders';
        } else if (url.includes('vacation')) {
            return 'Vacation';
        } else {
            return '';
        }
    }

    let agencyBrand = '';
    if (loading === false) {
        agencyBrand = getLinkText(surveyData.Agency.HomePageLink);
    }

    const headerBgColor = agencyBrand.toLowerCase().includes("vacation") ? "#33c590" : "white";

    if (loading || !surveyData) {
        return (
            <div className="flex justify-center items-center h-screen">
                <TailSpin
                    height="100"
                    width="100"
                    color="#0B829E"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                />
            </div>
        );
    }

    const profilePhotoUrl = surveyData ? surveyData.ProfilePhoto.replace(/&amp;/g, '&') : '';
    const headerPhotoUrl = surveyData ? surveyData.Agency.ReviewsLogo : '';

    return (
        <div className="SurveyLanding" style={{ backgroundColor: '#f8fbfb' }} >
            <header className="shadow-xl p-8 text-center relative" style={{ backgroundColor: headerBgColor }}>
                <a href={surveyData.Agency.HomePageLink} target="_blank" rel="noopener noreferrer">
                    <img
                        src={headerPhotoUrl}
                        alt=""
                        className="text-center"
                    />
                </a>
            </header>

            <div className="bg-white shadow-2xl pt-20 p-10 mt-24 mb-10 w-full max-w-2xl mx-auto text-center relative">
                <div className="relative">
                    <img
                        src={profilePhotoUrl}
                        alt="Profile"
                        className="absolute top-[-150px] left-1/2 transform -translate-x-1/2 w-32 h-36 object-cover rounded-lg shadow-lg"
                    />
                </div>
                <h2 className="text-lg font-bold mb-2 sm:font-lato sm:font-normal sm:text-[26px] sm:text-tlBlack sm:leading-[28px] sm:tracking-normal sm:mb-2">
                    {agencyBrand} Agent Experience Survey
                </h2>
                <h1 className="text-3xl text-tlBlue sm:font-lato sm:font-normal sm:text-[36px] sm:leading-[28px] sm:tracking-normal sm:mt-4">
                    {surveyData ? `${surveyData.FirstName.toUpperCase()} ${surveyData.LastName.toUpperCase()}` : ''}
                </h1>

                <p className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal text-left mt-10 sm:text-left">
                    Hello {surveyData.CustomerName},<br />
                    Our records indicate that you submitted an inquiry to <span className="font-bold">{surveyData ? `${surveyData.FirstName} ${surveyData.LastName}` : ''}</span> on <span className="font-bold">{formatDate(surveyData.ContactDate)}</span>.
                    Based on that inquiry, we'd love to hear about your experience working with <span className="font-bold">{surveyData ? `${surveyData.FirstName}` : ''}</span>.
                    We invite you to complete the following survey and share your experience. Thank you!
                </p>

                <div className="block md:hidden border-t border-gray-300 w-full my-4"></div>

                <p className="font-lato font-normal text-[16px] text-tlDarkGray leading-[24px] tracking-normal mt-10">
                    How many stars would you give <span className="font-bold">{surveyData ? `${surveyData.FirstName} ${surveyData.LastName}` : ''}</span> as an agent? <span className={`font-bold ${errors.rating ? 'text-tlRed' : 'text-tlBlue'}`}>*</span>
                </p>
                <div className="flex flex-col items-center mt-4">
                    <div className="flex flex-col items-center">
                        <StarRatings
                            rating={rating}
                            starRatedColor="#FFD700"
                            starEmptyColor="#d5ebf0"
                            starHoverColor="#FFD700"
                            changeRating={changeRating}
                            numberOfStars={5}
                            starDimension={starDimension}
                            starSpacing="1px"
                            starRatedClassName="star-rated"
                        />
                        <div className="flex justify-between w-full mt-2">
                            <span className="text-xs text-gray-600 font-bold pl-2">POOR</span>
                            <span className="text-xs text-gray-600 font-bold">EXCELLENT</span>
                        </div>
                    </div>
                </div>

                <div className="block md:hidden border-t border-gray-300 w-full my-4"></div>

                <div className="flex items-center justify-center flex-col mt-4">
                    <p className="font-lato font-normal text-[16px] text-tlDarkGray leading-[24px] tracking-normal mt-10">
                        Do you have any recommendation for <span className="font-bold">{surveyData ? `${surveyData.FirstName}` : ''}</span>? <span className={`font-bold ${errors.surveyDescription ? 'text-tlRed' : 'text-tlBlue'}`}>*</span>
                    </p>

                    <textarea
                        value={surveyDescription}
                        onChange={handleDescriptionChange}
                        className={`mt-2 px-4 py-2 w-full h-40 md:h-40 lg:h-24 rounded-lg border ${errors.surveyDescription || errors.surveyDescriptionLength ? 'border-tlRed' : 'border-gray-500'} focus:outline-none focus:border-black`}
                    />
                    {errors.surveyDescriptionLength && <p className="text-tlRed text-xs">Please enter no more than 2000 characters</p>}
                </div>

                <div className="block md:hidden border-t border-gray-300 w-full my-4"></div>

                <div className="flex items-center justify-center flex-col mt-4">
                    <p className="font-lato font-normal text-[16px] text-tlDarkGray leading-[24px] tracking-normal mt-10">
                        Have you booked or do you plan to book your trip using <span className="font-bold">{surveyData ? `${surveyData.FirstName}` : ''}</span>? <span className={`font-bold ${errors.yesOtherReason || errors.yesReasons || errors.noOtherReason || errors.noReasons ? 'text-tlRed' : 'text-tlBlue'}`}>*</span>
                    </p>
                    <div className="flex flex-col items-start mt-2">
                        <label className="flex items-center mt-2 font-bold">
                            <input
                                type="radio"
                                name="booking"
                                value="Y"
                                className="mr-2 accent-tlBlue"
                                checked={selectedStatus === 'Y'}
                                onChange={handleRadioChange}
                            />
                            <span className={selectedStatus === 'Y' ? 'text-tlDarkGray' : ''}>
                                Yes
                            </span>
                        </label>

                        <label className="flex items-center mt-2 font-bold">
                            <input
                                type="radio"
                                name="booking"
                                value="P"
                                className="mr-2 accent-tlBlue"
                                checked={selectedStatus === 'P'}
                                onChange={handleRadioChange}
                            />
                            <span className={selectedStatus === 'P' ? 'text-tlDarkGray' : ''}>
                                Not yet, but I plan to
                            </span>
                        </label>

                        <label className="flex items-center mt-2 font-bold">
                            <input
                                type="radio"
                                name="booking"
                                value="N"
                                className="mr-2 accent-tlBlue"
                                checked={selectedStatus === 'N'}
                                onChange={handleRadioChange}
                            />
                            <span className={selectedStatus === 'N' ? 'text-tlDarkGray' : ''}>
                                No
                            </span>
                        </label>

                        {errors.selectedStatus && <p className="text-tlRed text-xs pt-2">Please select an option</p>}
                    </div>
                </div>

                {selectedStatus === 'Y' || selectedStatus === 'P' ? (
                    <div>
                        <div className="flex items-center justify-center flex-col mt-4">
                            <p className="font-lato font-normal text-[16px] text-tlDarkGray leading-[24px] tracking-normal mt-10">
                                What are the reasons you booked or will book with <span className="font-bold">{surveyData ? `${surveyData.FirstName}` : ''}</span>?
                            </p>
                        </div>

                        <div className="flex flex-col sm:flex-row justify-center mt-2 text-left text-s">
                            <div className="flex flex-col mr-0 sm:mr-4">
                                <label className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason1"
                                        className="mr-2 accent-tlBlue"
                                        checked={yesReasons.yesReason1}
                                        onChange={() => handleYesReasonsChange('yesReason1')}
                                    />
                                    He or She was knowledgeable about my travel destination
                                </label>
                                <label className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason2"
                                        className="mr-2 accent-tlBlue"
                                        checked={yesReasons.yesReason2}
                                        onChange={() => handleYesReasonsChange('yesReason2')}
                                    />
                                    Working with this agent was convenient and easy
                                </label>
                                <label className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason3"
                                        className="mr-2 accent-tlBlue"
                                        checked={yesReasons.yesReason3}
                                        onChange={() => handleYesReasonsChange('yesReason3')}
                                    />
                                    The agent provided added value in the planning process
                                </label>
                            </div>
                            <div className="flex flex-col mt-4 sm:mt-0 mr-0 sm:mr-4">
                                <label className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason4"
                                        className="mr-2 accent-tlBlue"
                                        checked={yesReasons.yesReason4}
                                        onChange={() => handleYesReasonsChange('yesReason4')}
                                    />
                                    The agent crafted the perfect trip for me
                                </label>
                                <label className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason5"
                                        className="mr-2 accent-tlBlue"
                                        checked={yesReasons.yesReason5}
                                        onChange={() => handleYesReasonsChange('yesReason5')}
                                    />
                                    I was referred to this agent from a friend or family member
                                </label>
                                <label className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="yesReason6"
                                        className="mr-2 accent-tlBlue"
                                        checked={yesReasons.yesReason6}
                                        onChange={() => handleYesReasonsChange('yesReason6')}
                                    />
                                    Other:
                                    <input
                                        type="text"
                                        value={yesOtherReason}
                                        onChange={handleYesOtherReasonChange}
                                        className="mt-2 px-4 py-2 w-full rounded-lg border border-gray-500 focus:outline-none focus:border-black"
                                        disabled={!yesReasons.yesReason6}
                                    />
                                    {errors.yesOtherReason && <p className="text-tlRed text-xs pt-2">Please provide a reason</p>}
                                </label>
                            </div>
                        </div>
                        {errors.yesReasons && <p className="text-tlRed text-xs pt-4">Please, select at least one option</p>}
                    </div>
                ) : selectedStatus === 'N' ? (
                    <div>
                        <div className="flex items-center justify-center flex-col mt-4">
                                <p className="font-lato font-normal text-[16px] text-tlDarkGray leading-[24px] tracking-normal mt-10">
                                Why did you decide not to book with <span className="font-bold">{surveyData ? `${surveyData.FirstName}` : ''}</span>?
                            </p>
                        </div>

                        <div className="flex flex-col sm:flex-row justify-center mt-2 text-left text-s">
                            <div className="flex flex-col mr-0 sm:mr-4">
                                <label className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="noReason1"
                                        className="mr-2 accent-tlBlue"
                                        checked={noReasons.noReason1}
                                        onChange={() => handleNoReasonsChange('noReason1')}
                                    />
                                    Poor communication -I never heard back from this agent
                                </label>
                                <label className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="noReason2"
                                        className="mr-2 accent-tlBlue"
                                        checked={noReasons.noReason2}
                                        onChange={() => handleNoReasonsChange('noReason2')}
                                        />
                                    The agent didn't offer enough value or knowledge about my trip
                                </label>
                                <label className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="noReason3"
                                        className="mr-2 accent-tlBlue"
                                        checked={noReasons.noReason3}
                                        onChange={() => handleNoReasonsChange('noReason3')}
                                        />
                                    Working with this agent was expensive
                                </label>
                            </div>
                            <div className="flex flex-col mt-4 sm:mt-0 mr-0 sm:mr-4">
                                <label className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="noReason4"
                                        className="mr-2 accent-tlBlue"
                                        checked={noReasons.noReason4}
                                        onChange={() => handleNoReasonsChange('noReason4')}
                                        />
                                    I ended up not taking the trip
                                </label>
                                <label className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2">
                                    <input
                                        type="checkbox"
                                        name="noReason5"
                                        className="mr-2 accent-tlBlue"
                                        checked={noReasons.noReason5}
                                        onChange={() => handleNoReasonsChange('noReason5')}
                                        />
                                    I planned the trip by my own
                                </label>
                                    <label className="mt-2">
                                        <input
                                            type="checkbox"
                                            name="noReason6"
                                            className="font-lato font-normal text-[14px] text-tlDarkGray leading-[24px] tracking-normal mt-2"
                                            checked={noReasons.noReason6}
                                            onChange={() => handleNoReasonsChange('noReason6')}
                                        />
                                        <span className="ml-2">Other:</span>
                                        <input
                                            type="text"
                                            value={noOtherReason}
                                            onChange={handleNoOtherReasonChange}
                                            className="mt-2 px-4 py-2 w-full rounded-lg border border-gray-500 focus:outline-none focus:border-black"
                                            disabled={!noReasons.noReason6}
                                        />
                                        {errors.noOtherReason && <p className="text-tlRed text-xs pt-2">Please provide a reason</p>}
                                    </label>

                            </div>
                        </div>
                        {errors.noReasons && <p className="text-tlRed text-xs pt-4">Please select an option</p>}
                    </div>
                ) : null}


                {selectedStatus && (
                    <div className="flex justify-center mt-4">
                        <button
                            onClick={handleSubmit}
                            className="border-2 border-tlBlue bg-white text-tlBlue hover:bg-tlBlue hover:text-white rounded-full font-bold py-3 px-8 transition duration-300"
                        >
                            Submit
                        </button>
                    </div>
                )}

                {error && <p className="text-xs text-tlRed mt-2">{error}</p>}
            </div>
        </div>
    );
}

export default SurveyLanding;
