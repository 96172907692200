import React from 'react';
import '../App.css';
import { useLocation } from 'react-router-dom';

const ThankYou = () => {
    const location = useLocation();
    const { logo, agency } = location.state || {};

    const headerBgColor = agency && agency.toLowerCase().includes("vacation") ? "#33c590" : "white";

    return (
        <div className="ThankYou">
            <header className="shadow-md p-10 text-center relative" style={{ backgroundColor: headerBgColor }}>
                <a href={agency} rel="noopener noreferrer">
                    <img
                        alt=""
                        src={logo}
                        className="text-center"
                    />
                </a>
            </header>

            <div className="bg-white shadow-md p-20 mt-24 mb-10 w-11/12 max-w-2xl mx-auto text-center relative">
                <div className="relative">
                    <h1 className="text-3xl mb-2 pb-4 text-tlBlue">Thank you!</h1>
                    <h2 className="text-lg mb-2">Your response has been recorded</h2>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;
